import { BuilderActionType } from '@core/enums/builder-action-type.enum';

export class SetSubmittingStatus {
  public static readonly type = '[GENERAL] Set submitting status';

  constructor(
    public key: string,
    public status: boolean,
  ) {}
}

export class FetchActionsExecution {
  public static readonly type = '[General] Fetch action execution';

  constructor(public key: BuilderActionType) {}
}

export class UpdateChangedItem {
  public static readonly type = '[General] Update changed item';

  constructor(
    public property: any,
    public item?: any,
  ) {}
}
